import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import Img from "gatsby-image"
import img1 from "../../assets/img/cuisine/img-1.jpg"
import img2 from "../../assets/img/cuisine/img-2.jpg"
import img3 from "../../assets/img/cuisine/img-3.jpg"
import img4 from "../../assets/img/cuisine/img-4.jpg"
import img5 from "../../assets/img/cuisine/img-5.jpg"
import img6 from "../../assets/img/cuisine/img-6.jpg"
import img7 from "../../assets/img/cuisine/img-7.jpg"
import img8 from "../../assets/img/cuisine/img-8.jpg"
import img9 from "../../assets/img/cuisine/img-9.jpg"
import img10 from "../../assets/img/cuisine/img-10.jpg"
import catalogue from "../../assets/img/cuisine/catalogue.jpg"
import catalogueFile from "../../assets/pdf/catalogue-cuisine.pdf"
import cover from "../../assets/img/cuisine/cover.jpg"

const CuisinePage = ({ data }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  },
  [slider1, slider2]
  );
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  };
  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    arrows: false,
  };
  const g = data.gallery.edges.map(({ node }) => node.childImageSharp)
  return(
  <Layout>
    <SEO title="cuisine" />
    <div class="w-full relative">
            <div className="slider-wrapper">

              <Slider  {...settingsMain}  asNavFor={nav2} ref={slider => (setSlider1(slider))}>
              { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide" key={index}>
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                  </div>
                )}
              </Slider>
            </div>
            <div className="thumbnail-slider-wrap absolute left-0 right-0 bottom-0 mx-auto w-1/4">

                <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                    { data.sld.edges.map(({ node, index }) =>
                      <div className="slick-slide" key={index}>
                          <Img className="slick-slide-image rounded-full cursor-pointer" fluid={node.childImageSharp.thumb} />
                      </div>
                    )}
                </Slider>
            </div>
        </div>
    <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
      <h1 class="text-center text-2xl font-bold">Cuisine</h1>
      <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
    </div>
    <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <img src={cover} alt="cover" />
    </div>
    <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
      <h3 class="text-lg font-bold">Un projet Cuisine?</h3>
      <p>Spécialiste du plan travail en Solide Surface, nous nous chargeront de la prise des gabarits, la fabrication de votre plan travail sur mesure.</p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div><img src={img1} alt="1" /></div>
      <div><img src={img2} alt="2" /></div>
    </div>
     <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div><p>
        Après la prise de gabarits nous installerons votre plan travail sous 5 jours ouvrée.
        Nos réalisations Solide Surface pour la cuisine :</p>
        <ul class="mt-8 flex flex-col gap-5">
            <li class="flex gap-3 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class=" h-6" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <p>Plan de travail</p>
            </li>
            <li class="flex gap-3 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class=" h-6" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <p>Bar/Snack</p>
            </li>
            <li class="flex gap-3 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class=" h-6" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <p>Evier</p>
            </li>
            <li class="flex gap-3 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class=" h-6" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <p>Façades de meubles en Solide Surface sur mesure, etc…</p>
            </li>
        </ul>
      </div>
      <div><img src={img3} alt="3" /></div>
     </div>
     <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <img src={img4} alt="4" />
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div>
          <h2 class="uppercase font-bold text-xl my-3">SOLID SURFACE POUR LES PLANS DE TRAVAIL DE CUISINE</h2>
          <div class="border-solid border-2 border-red-500 w-12 mb-3"></div>
          <p class="font-bold">Plans de travail de cuisine : quel que soit le style de votre cuisine</p>
          <p class="text-justify mb-3">Créez des plans de travail de cuisine Solide Surface qui se prolongent avec des éviers et dosserets Solide Surface pour apporter imagination et fonction pratique à votre projet.</p>
          <p>Avec Solide Surface, vous pouvez exprimer librement vos idées créatives en cuisine en combinant esthétisme et fonctionnalité.</p>
      </div>
      <div><img src={img5} alt="5" /></div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div>
        <img src={img6} alt="6" />
        <img src={img7} alt="7" />
      </div>
        <div class="text-justify">
             
            <p class="font-bold mb-3">Beauté naturelle</p> 
            <p>
              La solid surface est facile à nettoyer et certifiée NSF/ANSI 51 pour le contact alimentaire.<br/>
  
              Solide Surface étant un matériau non poreux, les taches ne pénètrent pas dans la surface. Avec un nettoyage approprié, Solide Surface résiste aussi au développement de la moisissure et des bactéries.
            </p>

            <p class="font-bold mb-3 mt-3">Aspect homogène</p>

            <p>
              D’autres produits très performants, comme les éviers et les dosserets, peuvent être parfaitement intégrés pour créer une surface élégante à l’aspect monolithique. Avec un dosseret à congé, la salissure et la moisissure ne peuvent pas s’accumuler. Solide Surface est aussi parfaitement compatible avec les matériaux comme l’acier inoxydable, le bois et le verre.</p>
  
              <p class="mb-3 mt-3">Libérez votre imagination.</p>
              <p>
              Une cuisine n’est qu’un espace vide. Jusqu’à ce que vous la meubliez De votre imagination. C’est là une chose étonnamment simple à réaliser avec Solide Surface.<br/>
  
              Il vous suffit de le mouler. De le sculpter. D’en parer un mur Ou la face d’une armoire.
            </p>
           
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div class="text-justify">
      <p class="mb-2">Laissez votre imagination voguer Au gré des multiples coloris disponibles. Les règles sont abolies – seul compte votre plaisir.</p>

      <p class="mb-2">Pourquoi opter pour la banalité ? Solid Surface vous offre la liberté de concevoir une cuisine unique, À votre image. Vous pouvez marier Solid Surface avec pratiquement n’importe quel autre matériau. Et quand on dispose d’une telle profusion de coloris, de textures et d’options, c’ est un plaisir que d’explorer les possibilités qui s’offrent à vous.</p>

      <p class="font-bold mb-2">LA COLLECTION DES ÉVIERS EN Solid Surface</p>

      <p class="mb-2">Laissez libre cours à vos idées. Avec une telle profusion de formes et de tailles d’éviers en Solid Surface, votre créativité ne peut se trouver en panne d’idées. Votre évier fait partie intégrante de l’environnement global qu’il vous appartient de créer.</p>

      <p class="mb-2">Laissez les couleurs et les textures se fondre sans rupture, en toute fluidité. Finis les rebords, pièges à poussière et à germes! Vous pouvez désormais créer une union parfaite, mariant un style de classe et une existence saine et pleine de santé.</p>
      <img src={img10} alt="10" />
      </div>
      <div>
      <img src={img8} alt="8" class="mt-4 mb-10" />
      <img src={img9} alt="9" />

      </div>
    </div>
    <div class="grid grid-cols-1 mt-8 gap-8 md:grid-cols-3 md:mx-40 md:my-5 mx-3 gl gl50">
      <div class="col-1 md:col-span-2">
        <Gallery images={g} />
      </div>
      <div> 
       <a href={catalogueFile} class="relative block hover:opacity-70 hover-trigger" download>
          <img src={catalogue} alt="cataloge cuisine" class="my-0" />
          <div class="absolute left-0 right-0 text-center bottom-5 bg-white border border-grey-100 px-4 py-2 hover-target">
            Télécharger
          </div>
        </a>
      </div>
          
    </div>
  </Layout>
  )
}
 export const query = graphql`
 query CuisineGallery {
   gallery:allFile (filter:{relativeDirectory: {eq: "img/cuisine/g"}}){
     edges {
       node {
         childImageSharp {
           thumb: fluid(maxWidth: 270, maxHeight: 270) {
             ...GatsbyImageSharpFluid
           }
           full: fluid(maxWidth: 1024) {
             ...GatsbyImageSharpFluid
           }
         }
       }
     }
   }
   sld:allFile (filter:{relativeDirectory: {eq: "img/cuisine/s"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
 }
`
 export default CuisinePage